import React, { useState } from "react";
import "./Register.css";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import StepsContainer from "./StepsContainer";

const Register = () => {
  let history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [progressActive, setProgressActive] = useState(0);
  const [stepStatus, setStepStatus] = useState("wait");

  const checkField = () => {
    return firstName && secondName && email;
  };

  const handleRegister = () => {
    if (
      firstName.length === 0 ||
      secondName.length === 0 ||
      email.length === 0
    ) {
      message.error("All fields are mandatory");
      return;
    }
    setProgressActive(1);
    setStepStatus("finish");
    history.push("/register-details");
  };

  return (
    <>
      <div className="auth-container">
        <h2 className="register-text">
          Welcome to Medinflow, Let us create new account for you
        </h2>
        <div className="field-container">
          <div className="form-control">
            <input
              type="text"
              placeholder="First name"
              value={firstName}
              maxLength="20"
              className="custom-input"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="form-control">
            <input
              type="text"
              placeholder="Last name"
              value={secondName}
              maxLength="20"
              className="custom-input"
              onChange={(e) => setSecondName(e.target.value)}
            />
          </div>
        </div>
        <div className="email-container">
          <input
            type="email"
            placeholder="Last name"
            value={email}
            maxLength="30"
            className="custom-input"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <StepsContainer
        handleRegister={handleRegister}
        checkField={checkField}
        progressActive={progressActive}
        stepStatus={stepStatus}
      />
    </>
  );
};

export default Register;
