import React, { useState, useEffect } from "react";
import { Avatar, Modal, Tree, Switch, message, Spin } from "antd";
import { useParams, Link } from "react-router-dom";
import {
  MenuOutlined,
  ShareAltOutlined,
  BookOutlined,
  WarningOutlined,
  RightOutlined,
  DownOutlined,
  CarryOutOutlined,
  FormOutlined,
} from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";
import Chart from "../../components/Chart/Chart";
import axios from "axios";
import backImg from "../../assets/back.svg";
import crossImg from "../../assets/cross.svg";
import interaction from "../../assets/interaction_medinflow.svg";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useHistory } from "react-router-dom";

import "./Admin.css";
const { DirectoryTree } = Tree;

const Flowchart = () => {
  const { flowchartId } = useParams();
  let history = useHistory();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [modalDisplay, setModalDisplay] = useState("modal hidden");
  const [modalMobile, setModalMobile] = useState(false);
  const [sidebar, setSidebar] = useState("side-bar");
  const [modalData, setModalData] = useState({ title: "", body: "" });
  const [doctorModalData, setDoctorModalData] = useState({});
  const [doctorVisibility, setDoctorVisibility] = useState(false);
  const iconStyle = { fontSize: "1.5em", color: "#0080AB" };
  const [flowchartData, setFlowchartData] = useState([
    {
      id: "1",
      name: "Root",
      color: "#EE7977",
      featured_text: null,
      content: "",
      type: "square",
      parentId: "",
    },
  ]);
  const [flowchartContent, setFlowchartContent] = useState({});
  const [showTopic, setShowTopic] = useState(false);
  const [showSubTopic, setShowSubTopic] = useState(false);
  const [showFlowChart, setShowFlowChart] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [reportText, setReportText] = useState("");

  useEffect(() => {
    callFlowChartApi();
  }, [flowchartId]);

  const callFlowChartApi = () => {
    setModalDisplay("modal hidden");
    axios
      .get(`https://api.medinflow.com/api/v1/flow-chart/${flowchartId}`)
      .then((items) => {
        // console.log(items.data);
        setFlowchartData(items.data.data.data.flowchartData);
        setFlowchartContent(items.data.data);
        setDoctorData(items.data.data.doctors);
      })
      .catch((err) => console.log(err));
  };

  const isMobile = deviceWidth <= 480;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [deviceWidth]);

  const handleWindowSizeChange = () => {
    setDeviceWidth(window.innerWidth);
  };

  const handleSubmit = () => {
    // console.log(reportText);
  };
  const handleReport = () => {
    reportModal ? setReportModal(false) : setReportModal(true);
  };

  const loadCiteModal = () => {
    setModalData({
      title: "Citation",
      body: flowchartContent.data.citationData.content_html,
    });
    isMobile ? setModalMobile(true) : setModalDisplay("modal");
  };
  const random_rgba = () => {
    let o = Math.round,
      r = Math.random,
      s = 255;
    return (
      "rgba(" +
      o(r() * s) +
      "," +
      o(r() * s) +
      "," +
      o(r() * s) +
      "," +
      r().toFixed(1) +
      ")"
    );
  };

  const loadModal = (id) => {
    const data = flowchartData.filter((item) => item.id === id);
    // console.log(data);
    if (data[0].action === "flowchart") {
      // return history.push(`/flowchart/${data[0].formFlowchartId}`);
      if (!data[0].formFlowchartId) {
        return;
      } else {
        return history.push(`/flowchart/${data[0].formFlowchartId}`);
      }
    }
    if (data[0].action === "description" || !data[0].action) {
      setModalData({
        title: data[0].name,
        body: data[0].content_html,
      });
      isMobile ? setModalMobile(true) : setModalDisplay("modal");
    }
  };
  const loadDoctor = (index) => {
    setDoctorModalData(doctorData[index]);
    setDoctorVisibility(true);
  };
  const cancelDoctorModal = () => {
    setDoctorVisibility(false);
  };

  const toggleSidebar = () => {
    axios
      .get("https://api.medinflow.com/api/v1/full-detail")
      .then((res) => {
        // console.log(res.data.data);
        res.data.data.map((item) => {
          return delete Object.assign(item, {
            ["children"]: item["topics"],
          })["topics"];
        });
        res.data.data.map((item) => {
          item.children.map((subitem) => {
            return delete Object.assign(subitem, {
              ["children"]: subitem["subTopics"],
            })["subTopics"];
          });
        });
        return setTreeData(res.data.data);
      })
      .catch((err) => console.log(err));
    setSidebar("side-bar side-bar-visible");
  };
  // console.log(treeData);
  const showTopicHandler = () => {
    setShowTopic(true);
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", modalToFullscreen);
  //   return () => window.removeEventListener("scroll", modalToFullscreen);
  // }, []);

  // const modalToFullscreen = () => {
  //   setModalDisplay("modal fullscreen");
  // };

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: flowchartContent.name,
          text: "Check out our flowchart for Covid 19",
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard
        .writeText(
          `
          ${window.location.href}
          `
        )
        .then(
          function () {
            message.success("Share link copied successfully");
          },
          function (err) {
            message.error(err.message);
          }
        );
    }
  };

  const openBottomSheet = (open) => {
    setModalMobile(open);
  };

  const onSelect = (selectedKeys, info) => {
    if (!info.selectedNodes[0].hasOwnProperty("children")) {
      setSidebar("side-bar");
      history.push(`/flowchart/${selectedKeys[0]}`);
    }
  };

  return (
    <>
      {flowchartData.length === 1 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            marginTop: "25%",
            textAlign: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="container">
            <a className="go-back" onClick={() => history.goBack()}>
              <img src={backImg} width="70" height="30" />
            </a>
            <p
              className="source-sans-pro"
              style={{
                color: "#333",
                paddingLeft: "1.1rem",
                marginTop: "0.8rem",
              }}
            >
              <span>{flowchartContent.subject_name}</span>
              {" > "}&nbsp;
              <span>{flowchartContent.topic_name}</span>
              {" > "}&nbsp;
              <span>{flowchartContent.sub_topic_name}</span>
            </p>
            <h2
              className="source-sans-pro"
              style={{
                color: "#4A9BE5",
                fontWeight: "bold",
                paddingLeft: "1.1rem",
              }}
            >
              {/* {console.log(flowchartContent)} */}
              {flowchartContent.name}
              {flowchartContent.data &&
                flowchartContent.data.citationData &&
                Object.keys(flowchartContent.data.citationData).length > 0 && (
                  <sup style={{ cursor: "pointer" }} onClick={loadCiteModal}>
                    [cit.]
                  </sup>
                )}
            </h2>
          </div>
          <div
            style={{
              position: "relative",
              marginBottom: "35px",
            }}
          >
            {/* <div className="shapeImage">
              <img src={shape} alt="" />
              <span className="shape-text">Tap to interact</span>
            </div> */}
            <div className="shapeImage">
              <span className="shape-text">
                Tap to Open <br />
                Pinch to Zoom
              </span>
              <img src={interaction} alt="" width="26" />
            </div>
            {isMobile ? (
              <TransformWrapper>
                <TransformComponent
                  doubleClick={true}
                  maxScale={3}
                  velocitySensitivity={2}
                >
                  <Chart
                    data={flowchartData}
                    clickNode={loadModal}
                    chartZoom={"30%"}
                  />
                </TransformComponent>
              </TransformWrapper>
            ) : (
              <Chart data={flowchartData} clickNode={loadModal} />
            )}
          </div>
          <div className="bottom-main-container">
            <div className="bottom-container">
              <div
                className="bottom-edited"
                style={{ fontSize: ".7em", color: "#a3a3a3" }}
              >
                <em>
                  Last Edited on 24/07/2020
                  {/* <br />
                  by {flowchartContent.author_name} */}
                </em>
              </div>
              <div className="bottom-doctor" style={{ width: "100px" }}>
                <Avatar.Group
                  maxCount={3}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {doctorData.map((d, index) => {
                    return (
                      <Avatar
                        onClick={() => loadDoctor(index)}
                        style={{ backgroundColor: `${random_rgba()}` }}
                        key={index}
                      >
                        {d.name[0]}
                      </Avatar>
                    );
                  })}
                </Avatar.Group>
              </div>
            </div>
          </div>
          <div className="nav-bar">
            <div className="nav-bar-left-menu">
              {/* <button className="nav-button">
            <Link to="/flowchart/16dc9f80-f849-11ea-84fe-6fe6a5f0574d">
              <BookOutlined style={iconStyle} />
            </Link>
          </button> */}
              {/* <button className="nav-button" onClick={() => handleReport()}>
                <WarningOutlined style={iconStyle} />
              </button> */}
              <button className="nav-button" onClick={() => share()}>
                <ShareAltOutlined style={iconStyle} />
              </button>
            </div>
            <div className="nav-bar-right-menu">
              <button className="nav-button" onClick={() => toggleSidebar()}>
                <MenuOutlined style={iconStyle} />
              </button>
            </div>
          </div>
        </>
      )}

      {isMobile ? (
        <SwipeableBottomSheet
          open={modalMobile}
          onChange={openBottomSheet.bind(this)}
        >
          {/* <div className={modalDisplay}> */}
          <div style={{ padding: "15px", height: "90vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h2 style={{ color: "#0080AB" }}>{modalData.title}</h2>
              <button
                style={{
                  width: "30px",
                  height: "30px",
                  color: "f8f8f8",
                  background: "none",
                  border: "none",
                }}
                onClick={() => setModalMobile(false)}
              >
                <img src={crossImg} width="70" height="30" />
              </button>
            </div>
            <div className="line" />
            <div className="modalContent">
              {ReactHtmlParser(unescape(modalData.body))}
            </div>
            {/* <a href="#" className="read-more" onClick={() => modalToFullscreen()}>
            Read more
          </a> */}
          </div>
          {/* </div> */}
        </SwipeableBottomSheet>
      ) : (
        <div className={modalDisplay}>
          <div className="modalData source-sans-pro">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                style={{
                  width: "30px",
                  height: "30px",
                  color: "f8f8f8",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => setModalDisplay("modal hidden")}
              >
                <img src={crossImg} alt="" width="17" height="20" />
              </button>
            </div>
            <h2 style={{ color: "#4A9BE5", fontWeight: "600" }}>
              {modalData.title}
            </h2>
            {/* <div className="line" /> */}
            <div className="modalContent">
              {ReactHtmlParser(unescape(modalData.body))}
            </div>
            {/* <a href="#" className="read-more" onClick={() => modalToFullscreen()}>
            Read more
          </a> */}
          </div>
        </div>
      )}

      <div className={sidebar}>
        <ul style={{ color: "#0080AB" }}>
          <h3>Subjects</h3>
          <DirectoryTree
            showLine={{ showLeafIcon: false }}
            defaultExpandedKeys={["0-0-0"]}
            onSelect={onSelect}
            treeData={treeData}
          />
        </ul>
        <button
          style={{
            width: "30px",
            height: "30px",
            color: "f8f8f8",
            background: "none",
            border: "none",
            fontSize: "22px",
            cursor: "pointer",
          }}
          onClick={() => setSidebar("side-bar")}
        >
          X
        </button>
      </div>

      {reportModal ? (
        <div className="report-modal">
          <div className="report-inner">
            <h3>
              <WarningOutlined
                style={{
                  fontSize: "1em",
                  color: "#0080AB",
                  fontWeight: "bold",
                }}
              />{" "}
              Report Error
            </h3>
            <textarea
              className="warning-input"
              placeholder="Please enter your comments"
              value={reportText}
              onChange={(e) => setReportText(e.target.value)}
            />
            <button className="report-submit" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      ) : null}

      <Modal
        width="70%"
        title="Basic Modal"
        visible={doctorVisibility}
        title={null}
        footer={null}
        onCancel={cancelDoctorModal}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: "bold", margin: "0px" }}>
            {doctorModalData.name}
          </h1>
          <h3 style={{ fontWeight: "bold", margin: "0px" }}>
            {doctorModalData.degree}
          </h3>
          <div
            style={{
              display: "flex",
              width: "70%",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            {doctorModalData.facebook_url !== "" ? (
              <a href={doctorModalData.facebook_url}>
                <Avatar
                  src={
                    "https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"
                  }
                  size="small"
                />{" "}
              </a>
            ) : null}
            {doctorModalData.linkedin_url !== "" ? (
              <a href={doctorModalData.linkedin_url}>
                <Avatar
                  src={
                    "https://cdn4.iconfinder.com/data/icons/iconsimple-logotypes/512/linkedin-512.png"
                  }
                  size="small"
                />{" "}
              </a>
            ) : null}
            {doctorModalData.instagram_url !== "" ? (
              <a href={doctorModalData.instagram_url}>
                <Avatar
                  src={
                    "https://www.pngkey.com/png/full/283-2831746_insta-icon-instagram.png"
                  }
                  size="small"
                />{" "}
              </a>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Flowchart;
