import React, { useState } from "react";
import StepsContainer from "./StepsContainer";
import { message } from "antd";

const Details = () => {
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [profession, setProfession] = useState("");
  const [progressActive, setProgressActive] = useState(1);

  const checkField = () => {
    return state && city && profession;
  };

  const handleRegister = () => {
    if (state.length === 0 || city.length === 0 || profession.length === 0) {
      message.error("All fields are mandatory");
      return;
    }
    console.log(state, city, profession);
    // history.push("/");
  };

  return (
    <>
      <div className="auth-container">
        <h2 className="register-text">Enter you details</h2>
        <div className="field-container">
          <div className="form-control">
            <select
              className="custom-input"
              onChange={(e) => setState(e.target.value)}
            >
              <option value="" selected disabled>
                State
              </option>
              <option value="jharkhand">Jharkhand</option>
              <option value="jharkhand">Jharkhand</option>
              <option value="jharkhand">Jharkhand</option>
            </select>
          </div>
          <div className="form-control">
            <input
              type="text"
              placeholder="City"
              value={city}
              maxLength="20"
              className="custom-input"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </div>
        <div className="form-control">
          <select
            className="custom-input"
            onChange={(e) => setProfession(e.target.value)}
          >
            <option value="" selected disabled>
              Profession
            </option>
            <option value="business">Business</option>
            <option value="student">Student</option>
          </select>
        </div>
      </div>
      <StepsContainer
        checkField={checkField}
        progressActive={progressActive}
        handleRegister={handleRegister}
      />
    </>
  );
};

export default Details;
