import React from "react";
import Arrow from "../../assets/Line.svg";
import { Link } from "react-router-dom";
import { Steps } from "antd";

const { Step } = Steps;

const StepsContainer = ({
  checkField,
  handleRegister,
  progressActive,
  stepStatus,
}) => {
  return (
    <div className="register-bottom">
      <div className="progress-container">
        <Steps progressDot size="small" current={progressActive}>
          <Step title="Step 1" />
          <Step title="Step 2" status={stepStatus} />
        </Steps>
        <p style={{ marginTop: "15px" }}>
          <Link className="already-text" to="/login">
            Already have an account ?
          </Link>
        </p>
      </div>
      <div
        style={{
          background: checkField() ? "#0080ab" : "",
        }}
        className="submit-container"
      >
        <Link className="submit-inner" to>
          <img src={Arrow} className="submit" onClick={handleRegister} />
        </Link>
      </div>
    </div>
  );
};

export default StepsContainer;
