import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Flowchart from "./pages/Flowchart/Flochart";
import Home from "./pages/Home/Home";
import Admin from "./pages/Admin/Admin";
import FlowchartSingle from "./pages/Admin/Flowchart";
import Otp from "./pages/Login/Otp";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Details from "./pages/Register/Details";

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/otp">
            <Otp />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/register-details">
            <Details />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/flowchart/:flowchartId">
            <FlowchartSingle />
          </Route>
          <Route path="/flowchart">
            <Flowchart />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
