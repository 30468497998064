import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/M.png";
import Arrow from "../../assets/Line.svg";

import "./Home.css";
const Home = () => {
  return (
    <div className="home-container">
      <div className="first-home">
        <div className="home-header">
          {/* <Link to="/login">login</Link>
          <Link to="/register">Register </Link> */}
          <h1 className="about-heading">
            <div className="logo">
              <img className="logo-img" src={logo} alt="" />
            </div>{" "}
            <p className="about-sub">
              <span className="about-med">Med</span> <br />
              <span className="about-inflow">
                in<span style={{ fontWeight: "bold" }}>flow</span>
              </span>
            </p>{" "}
          </h1>
          <p className="about-subheading">
            Learning Medicine through{" "}
            <span style={{ fontWeight: "bold" }}>
              evidence based flowcharts
            </span>
          </p>

          <div className="top-line"></div>
          <div className="tilt-square"></div>
          <div className="bottom-line"></div>
          <div className="horizontal-line"></div>
          <div className="arrow-container">
            <Link
              to="/flowchart/19479a20-f84b-11ea-84fe-6fe6a5f0574d"
              className="arrow-inner"
            >
              <img src={Arrow} className="arrow" alt="" />
            </Link>
            <p className="get-started-text">Get Started</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
