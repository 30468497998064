import React, { useState } from "react";
import "./Login.css";
import group from "../../assets/Group.svg";
import { Link } from "react-router-dom";
import Arrow from "../../assets/Line.svg";
import { message } from "antd";
import { useHistory } from "react-router-dom";

const Login = () => {
  let history = useHistory();
  const [designDisplay, setDesignDisplay] = useState("");
  const [phone, setPhone] = useState("");

  const handleInput = (e) => {
    setPhone(e.target.value);
  };

  const handleLogin = () => {
    // console.log(phone);
    if (phone.length !== 10) {
      message.error("Enter valid mobile number");
      return;
    }
    if (phone.length === 10) {
      history.push({
        pathname: "/otp",
        phoneNum: phone,
      });
    }
  };

  return (
    <div className="main-container">
      <div
        style={{
          transition: "all 2s ease 3s",
          display: designDisplay,
        }}
        className="design-container"
      >
        <img src={group} alt="" className="design-img" />,
      </div>
      <div className="auth-container">
        <h2 className="login-header">Enter your mobile number</h2>
        <span className="plus">+91 </span>
        <input
          type="text"
          placeholder="Enter your mobile number"
          value={phone}
          maxLength="10"
          className="custom-inputs"
          onChange={handleInput}
          onClick={() => setDesignDisplay("none")}
        />
      </div>
      <div className="terms-container">
        <div className="text-container">
          <p>
            By continuing you may recieve an <br /> sms for verification
          </p>
        </div>
        <div
          style={{ background: phone.length === 10 ? "#0080ab" : "" }}
          className="submit-container"
        >
          <Link className="submit-inner">
            <img src={Arrow} className="submit" alt="" onClick={handleLogin} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
