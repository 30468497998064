import React, { useState } from "react";
import "./Otp.css";
import { Link } from "react-router-dom";
import Arrow from "../../assets/Line.svg";
import { useLocation } from "react-router-dom";

const Otp = () => {
  const location = useLocation();
  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  const [fourthField, setFourthField] = useState("");

  const handleChange = (e) => {
    if (e.keyCode === 8) {
      return;
    }
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 1].focus();
    e.preventDefault();
  };

  const handleOtp = () => {};

  const checkField = () => {
    return (
      firstField.length === 1 &&
      secondField.length === 1 &&
      thirdField.length === 1 &&
      fourthField.length === 1
    );
  };

  return (
    <>
      <div className="otp-container">
        <h2 className="login-header">Enter the 4 digit OTP</h2>
        <form className="otp-form">
          <input
            type="text"
            className="otp-input"
            autoFocus={true}
            maxLength="1"
            onChange={(e) => setFirstField(e.target.value)}
            onKeyUp={handleChange}
          />
          <input
            type="text"
            className="otp-input"
            maxLength="1"
            onChange={(e) => setSecondField(e.target.value)}
            onKeyUp={handleChange}
          />
          <input
            type="text"
            className="otp-input"
            maxLength="1"
            onChange={(e) => setThirdField(e.target.value)}
            onKeyUp={handleChange}
          />
          <input
            type="text"
            className="otp-input"
            maxLength="1"
            onChange={(e) => setFourthField(e.target.value)}
          />
        </form>
        <p className="resend-text">
          Resend the code : <span style={{ color: "red" }}>30 sec</span>
        </p>
      </div>
      <div className="otp-footer">
        <div className="text-container">
          <p>
            <Link className="otp-edit" to="/login">
              Edit my phone number
            </Link>
          </p>
        </div>
        <div
          style={{
            background: checkField() ? "#0080ab" : "",
          }}
          className="submit-container"
        >
          <Link className="submit-inner">
            <img src={Arrow} className="submit" alt="" onClick={handleOtp} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Otp;
